import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import BlogItem from './blog-item'
import freelancerIllustration from '../images/freelancer-illustration.svg'

class BlogList extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return posts && posts.length > 0 ? (
      <>
        {posts &&
          posts.map(({ node: post }) => (
            <div key={post.id}>
              <BlogItem post={post} />
            </div>
          ))}
      </>
    ) : (
      <div className="prose mx-auto my-10 px-4 md:px-0 flex flex-col items-center">
        <img
          alt="Coming Soon svg"
          className="block mx-auto w-3/4 md:w-1/2"
          src={freelancerIllustration}
        />
        <h2>Coming Soon...</h2>
      </div>
    )
  }
}

BlogList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

const Component = () => (
  <StaticQuery
    query={graphql`
      query BlogListQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                description
                date(formatString: "MMM DD, YYYY")
                tags
              }
              timeToRead
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogList data={data} count={count} />}
  />
)

export default Component
