import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import BlogList from '../../components/blog-list'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Blog"
          urlPath="/blog"
          keywords={[`software development`, `tech blog`]}
        />
        <div className="bg-white overflow-hidden">
          <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <h1 className="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              All Posts
            </h1>
            <BlogList />
          </div>
        </div>
      </Layout>
    )
  }
}
